import { Content } from '@prismicio/client';
import {
  SliceComponentProps,
  JSXMapSerializer,
  PrismicRichText,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import { PrismicNextLink } from '@/components/Internal/Button';
import { ArrowRightV2 } from '@/components/Unknown/Icons';
import { useMediaQuery, Theme } from '@material-ui/core';
import styles from './callToActionStyles.module.scss';

/**
 * Props for `CallToAction`.
 */
export type CallToActionProps = SliceComponentProps<Content.CallToActionSlice>;

/**
 * Component for "CallToAction" Slices.
 */

const CallToAction = ({ slice }: CallToActionProps): JSX.Element => {
  const isSmallDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );
  const buttonSize = isSmallDesktop ? 'small' : 'medium';
  const buttonRadius = isSmallDesktop ? 'circular' : 'rounded';

  const component: JSXMapSerializer = {
    heading2: ({ children }) => (
      <h2 className="text-pretty font-normal ~lg/desktop:~text-arc-4xl/arc-6xl">
        {children}
      </h2>
    ),
    paragraph: ({ children }) => (
      <p className={styles[slice.variation + '__sub-heading']}>{children}</p>
    ),
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={styles[slice.variation + '__section']}
    >
      <Container>
        <article className={styles[slice.variation + '__wrapper']}>
          <div className={styles[slice.variation + '__text-wrapper']}>
            <PrismicRichText
              field={slice.primary.heading}
              components={component}
            />
            <PrismicRichText
              field={slice.primary.sub_heading}
              components={component}
            />
          </div>
          <div className={styles[slice.variation + '__buttons-wrapper']}>
            <PrismicNextLink
              field={slice.primary.primary_button_link}
              endIcon={<ArrowRightV2 />}
              size={buttonSize}
              radius={buttonRadius}
              className={styles[slice.variation + '__primary-button']}
            >
              {slice.primary.primary_button_label}
            </PrismicNextLink>

            {slice.variation === 'default' && (
              <PrismicNextLink
                field={slice.primary.secondary_button_link}
                endIcon={<ArrowRightV2 />}
                size={buttonSize}
                variant="outlined"
                radius={buttonRadius}
                className={styles[slice.variation + '__secondary-button']}
                appearance="secondary"
              >
                {slice.primary.secondary_button_label}
              </PrismicNextLink>
            )}
          </div>
        </article>
      </Container>
    </section>
  );
};

export default CallToAction;
